import React from "react"
import styled from "styled-components"
import Layout from "../layout/layout"

const WrapperPolicy = styled.div`
    p{
        padding: 0 5vw;
    }
`;

export default function PrivacyPolicy() {
  return (
    <Layout pageTitle="Privacy Policy - Eugenio Maucieri" pageDescription="Miglior studio dentistico" pageKeywords="Salute, Denti, Chirurgia" pageAuthor="Eugenio Maucieri">
        <WrapperPolicy>
     <h1>Privacy-Policy</h1>
     <p>Gentile Cliente, desideriamo informarLa, ai sensi dell’art. 13 del D. Lgs. 196/2003, che lo studio dentistico Dr. EUGENIO MAUCIERI STUDIO DENTISTICO  procederà al trattamento dei dati personali da Lei forniti nel rispetto del Codice in materia di protezione dei dati personali.<br/><br/>
        Dr. EUGENIO MAUCIERI STUDIO DENTISTICO, informa inoltre ai sensi dell’art. 13 del Codice Privacy ed in ottemperanza alle prescrizioni del Provvedimento 229/2014 del Garante per la Protezione dei Dati Personali, che il presente sito utilizza le tipologie di cookie indicate al paragrafo sottostane relativo ai Cookies.<br/><br/>
        In particolare, Le forniamo le seguenti informazioni:<br/>
        1. I dati personali non sensibili da Lei forniti verranno trattati:<br/>
        • per conoscere ed analizzare le Sue richieste di informazioni, al fine di proporre le nostre migliori soluzioni;<br/>
        • per reperibilità e per gestione della corrispondenza;<br/>
        • per eseguire le attività relative alla vendita e alla consegna di prodotti o servizi;<br/>
        • per la definizione degli aspetti contrattuali;<br/>
        • per fini contabili, fiscali o di natura bancaria, derivanti da obblighi di legge.<br/><br/>

        2. Il trattamento dei dati personali sarà effettuato sia con l’ausilio di strumenti informatici che mediante supporto cartaceo, attraverso mezzi adeguati per prevenire la perdita dei dati, usi illeciti o non corretti ed accessi non autorizzati.<br/><br/>

        3. Il conferimento dei dati personali è facoltativo per quanto riguarda la richiesta di informazioni; è obbligatorio per adempiere agli obblighi di legge e contrattuali e l’eventuale rifiuto di fornire tali dati comporterebbe la mancata prosecuzione del rapporto.<br/><br/>

        4. I dati personali non sensibili da Lei forniti potranno essere comunicati ai nostri fornitori per svolgere al meglio la nostra attività,<br/><br/>

        5. Il titolare del trattamento è: <b>Dr. EUGENIO MAUCIERI STUDIO DENTISTICO,<br/><br/>

        Via G. Matteotti 61<br/><br/>

        06028 Sigillo<br/><br/>

        Telefono studio:075/9177098<br/><br/>

        Cell: 3923767904<br/><br/>

        Iscrizione albo odontoiatri Perugia n°: 834</b><br/><br/>

        Il responsabile del trattamento è:<br/><br/>

        <b>Dr. EUGENIO MAUCIERI STUDIO DENTISTICO,<br/><br/>

        Via G. Matteotti 61<br/><br/>

        06028 Sigillo<br/><br/>

        Telefono studio:075/9177098<br/><br/>

        Cell: 3923767904<br/><br/>

        Iscrizione albo odontoiatri Perugia n°: 834</b><br/><br/>

        , I dati personali verranno trattati dal nostro personale interno.<br/><br/>

        Desideriamo informarLa che in ogni momento potrà esercitare i Suoi diritti nei confronti del titolare del trattamento, ai sensi dell’art.7 del D. Lgs. 196/2003.

    Nessun dato importante viene scambiato tramite cookie. Essi consentono l’automazione di alcune operazioni risparmiandoti procedure complesse e sono talvolta essenziali per il corretto funzionamento del Sito.<br/>
    All’interno dei cookie vengono memorizzati dati che consentano l’integrità dei sistemi informatici che utilizza per accedere  al Sito.<br/>
    Nel caso non fosse d’accordo su tale prassi puoi disabilitare l’uso dei cookie dal tuo browser ma questo potrebbe causare difficoltà alla navigazione del Sito.<br/>

    <h2>COOKIES UTILIZZATI IN QUESTO SITO</h2>
    I seguenti cookie possono essere usati:<br/>
    Google Analytics: __utma, __utmb, __utmc, __utmz, _ga, _gat.<br/>
    Usiamo Google Analytics per monitorare il traffico e le visite nel sito.<br/>.<br/>

    Le grandezze principali che vengono misurate sono:   il numero di visitatori, parole chiave di ricerca usate per trovarci, pagine visitate sul sito o il tempo speso all’interno del sito.<br/>
    Le statistiche raccolte sono un requisito necessario per noi , al fine di fornire e migliorare i nostri prodotti e servizi, questo consente un valore aggiunto e ci aiuta a migliorare la leggibilità e la fruibilità dei contenuti.<br/><br/>

    I cookies non identificano gli utenti né associano il vostro indirizzo IP a nessuna informazione di identificazione personale. Questi cookie vengono utilizzati per raccogliere informazioni su come gli utenti utilizzano il nostro sito in maniera aggregata.<br/>
    I cookies raccolgono informazioni in forma anonima, compreso il numero di visitatori del sito o ad esempio le pagine che hanno visitato.<br/>
    Informazioni più dettagliate sono presenti a questo link:<br/>
    Google Privacy Policy<br/><br/>

    Ulteriori informazioni su come google utilizza i cookies e su come questi possano essere disabilitati potrà trovarle ai seguenti links:<br/>
    Tipo di cookie utilizzati da google<br/>
    Analytics usage<br/>
    Desideriamo informarla che usiamo  il cookie denominato: viewed_cookie_policy<br/><br/>

    viewed_cookie_policy  può assumere valore yes nel caso avesse accettato l’informativa sulla privacy e cookies.<br/><br/>

    Nessuna informazione personale è memorizzata in questo cookie, è necessario per non riproporre all’utente che naviga all’interno del sito il banner relativo all’accettazione dei cookies.<br/><br/>

    <h2>COME DISABILITARE TUTTI I COOKIES</h2>
    Le ricordiamo che può  rifiutare e/o bloccare i cookies in qualsiasi momento grazie alle impostazioni del suo browser.<br/><br/>

    Di seguito una lista di guide dei principali browser su come eseguire questa operazione.<br/><br/>

    • Gestione dei cookie in Firefox<br/>
    • Gestione dei cookie in Internet Explorer 11<br/>
    • Gestione dei cookie in Internet Explorer 10<br/>
    • Gestione dei cookie in Internet Explorer 9<br/>
    • Gestione dei cookie in Internet Explorer 7 e 8<br/>
    • Gestione dei cookie in Opera<br/>
    • Gestione dei cookie in Safari 3<br/>
    • Gestione dei cookie in Google Chrome<br/>
    </p>
    </WrapperPolicy>
    </Layout>
  )
}